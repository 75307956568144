<template>
  <base-section space="80">
    <v-container class="fill-height px-10 py-6">
      <v-row>
        <v-col v-if="$store.state.deliveryCart.products[0]" cols="12">
          <base-outlined-block
            v-for="(item, index) in $store.state.deliveryCart.products"
            :key="index"
            box-class="px-5 py-4 mb-8"
          >
            <v-row align="center" justify="space-between">
              <v-col class="px-0" cols="1">
                <base-btn
                  @click="removeProduct(index)"
                  color="soft-grey"
                  min-width="auto"
                  icon
                  font-size="small"
                >
                  <v-icon>mdi-minus-circle</v-icon>
                </base-btn>
              </v-col>
              <v-col class="px-3" cols="10">
                <v-row align="center" class="line-height-1">
                  <v-col class="pr-1" cols="2"> X{{ item.quantity }} </v-col>
                  <v-col class="px-0" cols="7">
                    <p class="font-weight-bold pb-1 mb-1 overflow-text">
                      {{ item.name }}
                    </p>
                    <div class="grey--text font-weight-medium text-body-2">
                      {{ getProductOptionsText(item) }}
                    </div>
                  </v-col>
                  <v-col
                    cols="3"
                    class="text-subtitle-2 font-weight-medium text-end"
                  >
                    {{ currencySymbol()
                    }}{{ formatPrice(getProductPrice(item), 2) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="px-0">
                <base-btn
                  @click="goToProduct(item, index)"
                  min-width="auto"
                  icon
                  font-size="small"
                >
                  <v-icon color="accent">mdi-chevron-right</v-icon>
                </base-btn>
              </v-col>
            </v-row>
          </base-outlined-block>
          <div class="font-weight-medium grey--text">
            Subtotal: {{ currencySymbol()
            }}{{
              formatPrice(
                $store.getters["deliveryCart/totalPriceWithoutShippingCost"],
                2
              )
            }}
          </div>
        </v-col>
        <v-col v-else cols="12">
          <base-subheading
            class="text-center text--disabled"
            title="No se encontró ningún producto!"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import { formatPrice, currencySymbol, getProductPrice } from "@/utils/price.js";
export default {
  methods: {
    formatPrice,
    currencySymbol,
    getProductPrice,
    removeProduct(index) {
      this.$store.dispatch("deliveryCart/removeProduct", index);
    },
    goToProduct(item, index) {
      this.$router.push({
        name: "Product",
        query: this.$route.query,
        params: {
          productId: item.pkId,
          deliveryCartProductIdentifier: index,
        },
      });
    },
    getProductOptionsText(item) {
      if (item.options && item.options[0]) {
        const descriptionWithOptions = item.options.reduce(
          (previousValue, currentValue) => {
            return `${previousValue}${previousValue ? "," : ""} ${
              currentValue.name
            } (X${currentValue.quantity})`;
          },
          ""
        );
        return descriptionWithOptions;
      }
      return item.description;
    },
  },
  mounted() {
    this.$store.commit("currentProduct/SET_SHOULD_RESET_OPTIONALS", true);
  },
};
</script>
<style scoped>
.overflow-text {
  overflow-wrap: anywhere;
}
.line-height-1 {
  line-height: 1;
}
</style>